<template>
    <v-app>
      <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
        <v-toolbar-items class="text-right">
          <v-btn
            large
            rounded
            dark
            href="javascript:window.print()"
            class="title mr-2 rounded"
            color="info"
          >
            <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
          </v-btn>
  
          <v-btn
            @click.stop="Export2Doc('exportContent', 'รายชื่อบุคลากรจากระบบหลัก')"
            class="mr-1"
            color="blue"
          >
            <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
          </v-btn>
          <v-btn
            @click.stop="
              exportToExcel('exportContent', 'รายชื่อบุคลากรจากระบบหลัก')
            "
            class="mr-1"
            color="green"
          >
            <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
          </v-btn>
        </v-toolbar-items>
      </v-system-bar>
  
      <div
        id="exportContent"
        style="mso-page-orientation: landscape; overflow-x: scroll;"
      >
        <div class="page">
          <table width="100%" class="text_j">
            <tr class="th">
              <td class="td text-center">ลำดับ</td>
              <td class="td text-center">รหัสสาขาวิชา</td>
              <td class="td text-center">สาขาวิชา</td>          
              <td class="td text-center">จำนวนข้าราชการครู</td>
              <td class="td text-center">จำนวนพนักงานราชการครู</td>
              <td class="td text-center">จำนวนครูจ้างสอน</td>           
            
            </tr>
            <tr v-for="(item, index) in personnel_etcs" :key="item.id">
              <td class="td pa-1 text-center">{{ ++index }}</td>
              <td class="td pa-1 text-center">{{ item.personnel_etc_branch }}</td>
              <td class="td pa-1 text-center">{{ item.type_courseN }}</td>
              <td class="td pa-1 text-center">{{ item.CountTeachbranch }}</td>
              <td class="td pa-1 text-center">{{ item.CountGovhbranch }}</td>
              <td class="td pa-1 text-center">{{ item.CountTemhbranch }}</td>
             
            </tr>
          </table>
  
     
        </div>
      </div>
    </v-app>
  </template>
  
  <script>
  export default {
    name: "App",
    data: () => ({
      ApiKey: "HRvec2021",
      personnel_etcs: []
    }),
  
    async mounted() {
      await this.sweetAlertLoading();
      await this.personnel_etcQueryAll();
      Swal.close();
    },
  
    methods: {
      async sweetAlertLoading() {
        Swal.fire({
          title: "Please Wait !",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          onAfterClose: () => {
            Swal.hideLoading();
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      },
  
      async personnel_etcQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("personnel_etc.php", {
            ApiKey: this.ApiKey,
            branchCount: "Ok"
          })
          .then(result => {           
            this.personnel_etcs = result.data;
          })
          .finally(() => (this.loading = false));
      },
  
      Export2Doc(element, filename) {
        var preHtml =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html =
          preHtml + document.getElementById(element).innerHTML + postHtml;
  
        var blob = new Blob(["\ufeff", html], {
          type: "application/msword"
        });
  
        // Specify link url
        var url =
          "data:application/vnd.ms-word;charset=utf-16," +
          encodeURIComponent(html);
  
        // Specify file name
        filename = filename ? filename + ".doc" : "document.doc";
  
        // Create download link element
        var downloadLink = document.createElement("a");
  
        document.body.appendChild(downloadLink);
  
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = url;
  
          // Setting the file name
          downloadLink.download = filename;
  
          //triggering the function
          downloadLink.click();
        }
  
        document.body.removeChild(downloadLink);
      },
      exportToExcel(element, filename) {
        var preHtml =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
        var postHtml = "</body></html>";
        var html =
          preHtml + document.getElementById(element).innerHTML + postHtml;
  
        var blob = new Blob(["\ufeff", html], {
          type: "application/msexcel"
        });
  
        // Specify link url
        var url =
          "data:application/vnd.ms-excel;charset=utf-8," +
          encodeURIComponent(html);
  
        // Specify file name
        filename = filename ? filename + ".xls" : "document.xls";
  
        // Create download link element
        var downloadLink = document.createElement("a");
  
        document.body.appendChild(downloadLink);
  
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = url;
  
          // Setting the file name
          downloadLink.download = filename;
  
          //triggering the function
          downloadLink.click();
        }
        document.body.removeChild(downloadLink);
      }
    },
    computed: {
      cid() {
        return this.$route.query.cid;
      }
    },
    props: {
      source: String
    }
  };
  </script>
  <style>
  body {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16px;
    font-weight: 250;
  }
  
  .u {
    border-bottom: 1px dotted #000;
    text-decoration: none;
  }
  u {
    border-bottom: 1px dotted #000;
    text-decoration: none;
  }
  
  * {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  
  .table,
  .th,
  .td {
    border-bottom: 0.5px solid black;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-collapse: collapse;
  }
  
  .text_j {
    border-collapse: collapse;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1;
  }
  .text_l {
    border-collapse: collapse;
    text-align: left;
    text-justify: inter-word;
    line-height: 1;
  }
  
  tfoot tr:hover {
    background-color: initial;
  }
  
  tbody tr:hover {
    background-color: inherit;
  }
  
  td,
  th {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
  }
  
  tfoot td {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-weight: bold;
  }
  
  #verticaltext {
    writing-mode: tb-rl;
    filter: flipv fliph;
    -webkit-transform: rotate(-90deg);
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 0;
  }
  
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    width: 21cm;
    min-height: 29.7cm;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 0cm;
    margin-right: auto;
    margin-bottom: 0cm;
    margin-left: auto;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1cm;
  }
  
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 22pt;
    font-weight: bold;
    line-height: 1.5;
  }
  
  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }
  
  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }
  
  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }
  
  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
    font-weight: bold;
  }
  
  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 12pt;
  }
  
  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }
  
  .largerCheckbox {
    width: 30px;
    height: 30px;
    color: blue;
  }
  .td_line {
    border-bottom: 1pt dotted black;
  }
  /*.subpage {
            height: 247mm;
          }*/
  
  .BlackBold10 {
    font-weight: bold;
  }
  
  #content {
    display: table;
  }
  
  #pageFooter {
    display: table-footer-group;
  }
  
  #pageFooter:after {
    counter-increment: page;
    content: counter(page);
  }
  
  @media print {
    .page {
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      padding: initial;
      page-break-after: always;
    }
  
    .head {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    .regular12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .regular16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .bold16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blod12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blackRegula10 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 14pt;
    }
  
    .blackBold18 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    /* .head {
              font-size: 18px;
              font-weight: bold;
            }
            .regular12 {
              font-size: 12px;
            }
            .blod12 {
              font-size: 12px;
              font-weight: bold;
            }
            .blackRegula8 {
              font-size: 8px;
            } */
    .noprint {
      display: none;
    }
  
    .gap-10 {
      width: 100%;
      height: 10px;
    }
    .gap-20 {
      width: 100%;
      height: 20px;
    }
    .gap-30 {
      width: 100%;
      height: 30px;
    }
  }
  </style>
  